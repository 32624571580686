import { useEffect } from 'react';

export default function useLocationHashCallback(hash: string, callback: () => void) {
  useEffect(() => {
    const onHashChange = () => {
      if(window.location.hash === hash) callback();
    }
    // Whenever the location already is #cookie-settings, open the modal
    if(window.location.hash === hash) callback();

    // window. is needed because otherwise we need to keep a references which leads to unnecessary re-renders
    // or a lot of extra useCallback code.
    window.addEventListener('hashchange', onHashChange);

    return () => {
      window.removeEventListener('hashchange', onHashChange);
    };
  }, [hash, callback]);
}
