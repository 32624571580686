import React from 'react';
import PropTypes from 'prop-types';

import { isValidHeading } from './helpers/validators';
import createHeadingId from './helpers/create-heading-id';

const Heading = props => {
  if (!isValidHeading(props)) {
    return null;
  }
  const { htmlTag: Tag, className, id, children = [], text } = props;

  if (!children.length && text) {
    children.push(text);
  }

  return (
    <Tag className={className}>
      {children.map((child, index) => {
        if (typeof child === 'string') {
          return child;
        }

        if (
          child.props &&
          (child.props.markType === 'tocHeading' ||
            child.props.markType === 'scrollLink')
        ) {
          return (
            <>
              <div
                key={index}
                id={createHeadingId(child.props.value)}
                className='toc-header-link'
              />
              <span key={index} style={{ color: child.props?.value?.color }}>
                {child}
              </span>
            </>
          );
        }

        return (
          <span
            key={index}
            id={id}
            style={{ color: child.props?.value?.color }}
          >
            {child}
          </span>
        );
      })}
    </Tag>
  );
};

const headingTag = PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']);

Heading.propTypes = {
  htmlTag: headingTag,
  className: PropTypes.string,
  id: PropTypes.string,
  children: PropTypes.array,
  text: PropTypes.string
};

export default Heading;
