import React, {ChangeEventHandler, useContext, useEffect, useState} from "react";

import { ConsentSettings } from "../services";
import InformationSection from "../components/InformationSection";
import PrivacyLinks from "../components/PrivacyLinks";
import { SanityDataContext } from "../context/sanity-data-context";
import { PageScroll } from "../utils";
import { onConsentEventHandler } from "../types/on-consent-event-handler";

const SettingsModal = ({ value, onSubmit }: {value: ConsentSettings, onSubmit: onConsentEventHandler}) => {
  const [consentSettings, setConsentSettings] = useState(value);
  const context = useContext(SanityDataContext);

  const consentCategories = {
    required: context?.consentCategories?.requiredSection,
    preferences: context?.consentCategories?.preferenceSection,
    statistics: context?.consentCategories?.statisticsSection,
    marketing: context?.consentCategories?.marketingSection,
    unclassified: context?.consentCategories?.notClassifiedSection,
  };

  useEffect(() => {
    PageScroll.lock();

    return () => {
      PageScroll.release();
    };
  }, []);

  const handleAcceptAll = () => {
    const settings = ConsentSettings.createAcceptAllSettings();
    onSubmit(settings, "accept all 2");
  };

  const handleSubmit = () => {
    const settings = new ConsentSettings(consentSettings);

    onSubmit(settings, "confirm my choices");
  };

  const toggleSwitch: ChangeEventHandler<HTMLInputElement> = (e) => {
    const settings = new ConsentSettings({
      ...consentSettings,
      [e.target.name]: e.target.checked,
    })
    setConsentSettings(settings);
  };

  const buttonTestIdMap = {
    acceptAll: "btn-settings-accept-all",
    acceptSelection: "btn-settings-accept-selected"
  }

  const buttonActionMap = {
    acceptAll: handleAcceptAll,
    acceptSelection: handleSubmit
  };

  const settingsScreenConsentButtons =
    context?.settingsScreenConsentButtons?.map(button => (
      <button
        data-test-id={buttonTestIdMap[button.action]}
        type='button'
        key={button._key}
        onClick={buttonActionMap[button.action]}
        className={`cookie-modal__btn ${
          context?.settingsScreenConsentButtons.length > 2
            ? `-no-min-width flex-grow flex-basis-0`
            : null
        } -${button.type || 'primary'}`}
      >
        {button.text}
      </button>
    ));

  return (
    <div className="-step-2 cookie-modal -xlarge -active">
      <div className="cookie-modal__body" data-test-id="settings-modal" role="dialog" aria-modal="true">
        <div className="cookie-modal__section cookie-modal__section--heading">
          <h3>{context?.settingsScreenTitle}</h3>
        </div>
        <div className="cookie-modal__section">
          <p className="mb-6x mb-sm-3x">
            {context?.settingsScreenDescription}
          </p>
          <form>
            {Object.keys(consentCategories).map((category) => (
              <InformationSection
                key={category}
                onToggle={toggleSwitch}
                isChecked={category === "required" || consentSettings[category]}
                isDisabled={category === "required"}
                cookieName={category}
                title={consentCategories[category]?.title}
                info={consentCategories[category]?.info}
                description={consentCategories[category]?.description}
                companies={consentCategories[category]?.companies}
                context={context}
              />
            ))}

            <div className="flex-column flex-row-sm end-sm gap-2x mt-10x mt-sm-5x">
              {settingsScreenConsentButtons}
            </div>
          </form>

          <PrivacyLinks/>
        </div>
      </div>
    </div>
  );
};

export default SettingsModal;
