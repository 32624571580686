import React, { useState } from "react";
import { LocalStorageService } from "../services";

import ModalContainer from "./modal-container";
import ScriptsContainer from "./scripts-container";
import { ConsentSettings, SubmitService } from "../services";
import { PageScroll } from "../utils";

import {onConsentEventHandler} from "../types/on-consent-event-handler";

export function AppContainer () {
  const savedSettings = LocalStorageService.loadSettingsFromLocalStorage();
  const defaultSettings = ConsentSettings.createEssentialSettings();

  const [settings, setSettings] = useState(savedSettings || defaultSettings);

  React.useEffect(() => {
    if (savedSettings) SubmitService.initalSubmit(savedSettings);
  }, []);

  const handleSettingsChoice: onConsentEventHandler = (consentSettings, text) => {
    setSettings(consentSettings);
    SubmitService.handleSubmit(consentSettings, text);
    window.location.hash = '';
    PageScroll.release();
  };

  return (<>
    <ModalContainer onSettingsChoice={handleSettingsChoice} settings={settings} savedSettings={savedSettings} />
    <ScriptsContainer settings={settings} />
  </>);
}
