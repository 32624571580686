const getAttributesFromBehaviour = behavior => {
  if (behavior === 'download') {
    return { download: true };
  }

  if (behavior === 'newWindow') {
    return { target: '_blank' };
  }

  return {};
};

export default getAttributesFromBehaviour;
