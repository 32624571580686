import React from 'react';
import PropTypes from 'prop-types';
import { PortableText } from '@portabletext/react';
import { CardAlert } from '@youngcapital/origin-component-library/external';

import Heading from './heading';
import Table from './table';
import AnchorElement from './anchor-element';
import List from './list';

export type BlockContentContext = {
  dataPrivacyUrl?: string;
  imprintUrl?: string;
};

const components = (context: BlockContentContext) => ({
  list: props => <List {...props} />,
  listItem: props => (
    <li>
      <span>{props.children}</span>
    </li>
  ),
  block: {
    ...['h2', 'h3', 'h4', 'h5', 'h6'].reduce(
      (tags, tag) => ({
        ...tags,
        [tag]: props => (
          <Heading
            htmlTag={props.value.style}
            className='mb-1x'
            {...props}
          />
        )
      }),
      {}
    )
  },
  types: {
    cookieRichContent: ({ value }) => (
      <BlockContent blocks={value.content} className='mt-4x mb-4x' context={context} />
    ),
    inlineTable: ({ value }) => <Table {...value} />,
    cardAlert: ({ value }) => (
      <CardAlert variation={value.variation}>
        <BlockContent blocks={value.content} />
      </CardAlert>
    )
  },
  marks: {
    link: props => <AnchorElement {...props} />,
    emailLink: props => <AnchorElement {...props} />,
    legalLink: props => {
      const url = {
        dataPrivacyUrl: context.dataPrivacyUrl,
        imprintUrl: context.imprintUrl
      };

      return (<AnchorElement {...props} value={{href: url[props.value.type]}} />) }
    ,
    color: ({ markKey, value, text }: { markKey?: string, value?: { color?: string }, text?: string }) => {
      if (!value || !text) {
        return null;
      }

      const style = { color: value.color };

      return (
        <>
          <span key={markKey} style={style}>
            {text}
          </span>
        </>
      );
    }
  }
});

const BlockContent = ({ blocks, context = {}, ...props }) => (
  <PortableText
    value={blocks}
    components={components(context)}
    {...props}
  />
);

BlockContent.propTypes = {
  blocks: PropTypes.array,
  className: PropTypes.string,
  lang: PropTypes.string,
  inverted: PropTypes.bool,
  overrides: PropTypes.object
};

export default React.memo(BlockContent);
