import { Helmet } from "react-helmet-async";
import { Environment }  from "../../utils";
import { gtmSnippet } from "./GtmSnippet";

const GtmScript = () => {
  const GtmScriptsNotAdded = !document.querySelectorAll('[src*="https://www.googletagmanager.com/gtm.js"]').length;
  const gtmId = Environment.getGtmId();

  return (
    gtmId &&
        GtmScriptsNotAdded &&
        gtmSnippet(gtmId) &&
        <Helmet>
          <noscript>
            {`<iframe
                src='https://www.googletagmanager.com/ns.html?id=${gtmId}'
                height='0'
                width='0'
                style='display: none; visibility: hidden'
            />`}
          </noscript>
        </Helmet>
  );
};

export default GtmScript;
