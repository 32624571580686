const ESSENTIAL_SETTINGS =
  { preferences: false, statistics: false, marketing: false, unclassified: false };

const ACCEPT_ALL_SETTINGS =
  { preferences: true, statistics: true, marketing: true, unclassified: true };

export default class ConsentSettings {
  private preferences: boolean;
  private statistics: boolean;
  private marketing: boolean;
  private unclassified: boolean;

  constructor(settings = {}) {
    const merged = { ...ESSENTIAL_SETTINGS, ...settings};

    this.preferences = merged.preferences;
    this.statistics = merged.statistics;
    this.marketing = merged.marketing;
    this.unclassified = merged.unclassified;
  }

  get isOnlyEssential() {
    return JSON.stringify(this) === JSON.stringify(ESSENTIAL_SETTINGS);
  }

  static createEssentialSettings() {
    return new ConsentSettings(ESSENTIAL_SETTINGS);
  }

  static createAcceptAllSettings() {
    return new ConsentSettings(ACCEPT_ALL_SETTINGS);
  }

  acceptedSettings() {
    return Object.keys(this).filter(k => this[k])
  }

  rejectedSettings() {
    return Object.keys(this).filter(k => !this[k])
  }
}
