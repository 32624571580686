import * as LocalStorageService from "./local-storage-service";
import * as GTMService from "./gtm-service";
import * as BFFService from "./bff-service";
import BlueConicService from "./blue-conic-service";

import { Environment } from './../utils';
import ConsentSettings from "./consent-settings-service";

function setConsentToBlueConic(consentSettings: ConsentSettings) {
  if(Environment.isBlueConicEnabled()) {
    BlueConicService.setConsent(consentSettings);
  }
}

function initalSubmit(consentSettings: ConsentSettings) {
  setConsentToBlueConic(consentSettings);
  GTMService.setConsent(consentSettings, "");
}

function handleSubmit(consentSettings: ConsentSettings, buttonText = "") {
  const previousSettings = LocalStorageService.loadSettingsFromLocalStorage();

  setConsentToBlueConic(consentSettings);

  if (previousSettings) {
    GTMService.updateConsent(consentSettings, buttonText);
  } else {
    GTMService.setConsent(consentSettings, buttonText);
  }

  if(Environment.isBFFEnabled()){
    BFFService.submitPreferences(consentSettings);
  }

  LocalStorageService.setSettingsInLocalStorage(consentSettings);
}

export { initalSubmit, handleSubmit };
