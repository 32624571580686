import React, {ChangeEventHandler} from 'react';

export default function Switch({ isChecked, name, onToggle }: { isChecked: boolean, name: string, onToggle: ChangeEventHandler<HTMLInputElement> }) {
  return (
    <>
      <input key={name}
        name={name}
        className="cookie-modal-input__switch"
        id={`CookieInputCheckbox_${name}`}
        data-test-id={`toggle-${name}`}
        type="checkbox"
        defaultChecked={isChecked}
        onChange={onToggle} 
        disabled={name === "required"} />
      <label className="cookie-modal-input__switch-btn" htmlFor={`CookieInputCheckbox_${name}`}></label>
    </>
  );
}
