import ConsentSettings from "./consent-settings-service";

export enum EventType {
  Consent = 'consent',
  ConsentUpdate = 'consent_change'
}

function pushToLayer(settings: ConsentSettings, eventType: EventType, buttonText: string) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: eventType,
    button_text: buttonText,
    consentlevel: settings
  });
}

export function setConsent(settings: ConsentSettings, buttonText: string) {
  pushToLayer(settings, EventType.Consent, buttonText);
}

export function updateConsent(settings: ConsentSettings, buttonText: string) {
  pushToLayer(settings, EventType.ConsentUpdate, buttonText);
}
