import React from "react";
import { Environment } from "../../utils";
import { BlueConicUrlList } from "./BlueConicUrlList";
import BlueConicService from "../../services/blue-conic-service";

const BlueConicScript = () => {
  const scriptId = "blue-conic-script";

  const onLoad = () => BlueConicService.handleScriptLoading();

  const url = BlueConicUrlList
    .filter(item => item.domainId === Environment.getCurrentDomainId())
    .map(item => item.url)
    .join();

  React.useEffect(() => {
    if (!document.getElementById(scriptId) && !!url) {
      const script = document.createElement("script");
      script.src = url;
      script.async = true;
      script.onload = onLoad;
      script.id = scriptId;
      script.dataset.testId = scriptId;
      document.body.appendChild(script);
    } else if (window.blueConicClient) {
      onLoad();
    }
  });

  return null;
};

export default BlueConicScript;
