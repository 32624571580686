import React, { useEffect, useContext, ReactNode } from "react";
import BlockContent, { BlockContentContext } from "../cms-components/block-content";
import PropTypes from "prop-types";
import { SanityDataContext } from "../context/sanity-data-context";
import { ConsentSettings } from "../services";
import { PageScroll } from "../utils";
import PrivacyLinks from "../components/PrivacyLinks";
import { onConsentEventHandler } from "../types/on-consent-event-handler";
import { CookieRichContent } from "../types/cookie-banner-settings";

const EntryScreenModal = ({ onSubmit, onSettings }: { onSubmit: onConsentEventHandler, onSettings: () => void }) => {
  const context = useContext(SanityDataContext);

  useEffect(() => {
    PageScroll.lock();

    return () => {
      PageScroll.release();
    };
  }, []);

  const handleDeclineAll = () => {
    const settings = ConsentSettings.createEssentialSettings();
    onSubmit(settings, "only necessary");
  };

  const handleAcceptAll = () => {
    const settings = ConsentSettings.createAcceptAllSettings();
    onSubmit(settings, "accept all 1");
  };

  const handleSettings = () => {
    onSettings();
  };

  function renderRichContent(richContent: CookieRichContent, contextObj: BlockContentContext): ReactNode {
    return <BlockContent blocks={[richContent]} context={contextObj} />
  }

  const buttonActionMap = {
    acceptAll: handleAcceptAll,
    declineAll: handleDeclineAll,
    openSettings: handleSettings
  };

  const buttonTestIdMap = {
    acceptAll: "btn-accept-all",
    declineAll: "btn-decline",
    openSettings: "btn-settings"
  }

  const entryScreenConsentButtons = context?.entryScreenConsentButtons?.map(
    button => (
      <button
        data-test-id={buttonTestIdMap[button.action]}
        type='button'
        key={button._key}
        onClick={buttonActionMap[button.action]}
        className={`cookie-modal__btn ${context?.entryScreenConsentButtons.length > 2
          ? `-no-min-width flex-grow flex-basis-0`
          : null
        } -${button.type || 'primary'} ${button.action === 'openSettings' ? 'hidden-sm' : null
        }`}
      >
        {button.text}
      </button>
    )
  );

  const settingsButton = context?.entryScreenConsentButtons
    ?.filter(button => button.action === 'openSettings')
    .map(button => (
      <button
        data-test-id={buttonTestIdMap[button.action]}
        type='button'
        className='cookie-modal__btn -link'
        onClick={buttonActionMap[button.action]}
      >
        {button.text}
      </button>
    ));

  return (
    <div
      data-test-id="the-prothena-cookie-modal"
      className="cookie-modal -space-around -overflow -active -xlarge"
      role="presentation"
      aria-hidden="false"
    >
      <div className="cookie-modal__body">
        <div className="cookie-modal__section">
          <h3 className="mb-1x">{context?.entryScreenTitle}</h3>
          <div className="mb-6x mb-sm-3x">
            {typeof context?.entryScreenDescription === 'string' ? (<p>{context?.entryScreenDescription}</p>)
              : context?.entryScreenDescription && renderRichContent(context.entryScreenDescription, {
                dataPrivacyUrl: context?.dataPrivacyUrl,
                imprintUrl: context?.imprintUrl,
              })}
          </div>

          <div className='flex-column flex-row-sm stretch-sm gap-2x'>
            {entryScreenConsentButtons}
          </div>

          {settingsButton && (
            <div className='flex-row center-sm mt-3x hidden-lg hidden-md hidden-xs'>
              {settingsButton}
            </div>
          )}

          {context?.forceShowLegalLinksInFooter && (<PrivacyLinks />)}
        </div>
      </div>
    </div>
  );
};

EntryScreenModal.propTypes = {
  onSettings: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default EntryScreenModal;
