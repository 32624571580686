export const BlueConicUrlList = [
  {
    domainId: 'YC_NL',
    url: 'https://e205.youngcapital.nl/script.js'
  },
  {
    domainId: 'YC_NEXT',
    url: 'https://e205.youngcapital.nl/script.js'
  },
  {
    domainId: 'YC_DE',
    url: 'https://m600.youngcapital.de/script.js'
  }
];
