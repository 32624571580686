import ConsentSettings from './consent-settings-service';

const SETTINGS_KEY = 'consent-settings';

export function loadSettingsFromLocalStorage() {
  try {
    const settings = JSON.parse(localStorage.getItem(SETTINGS_KEY) || '');
    if (settings === null) return null;
    return new ConsentSettings(settings);
  } catch {
    return null;
  }
}

export function setSettingsInLocalStorage(settings: ConsentSettings) {
  localStorage.setItem(SETTINGS_KEY, JSON.stringify(settings));
}

export function clearSettingsInLocalStorage() {
  localStorage.removeItem(SETTINGS_KEY);
}