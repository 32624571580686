import PropTypes from 'prop-types';
import { List as ListComponent } from '@youngcapital/origin-component-library/external';

const getVariation = type => {
  switch (type) {
  case 'checkmark':
    return 'checkmarks';
  case 'crosses':
    return 'crosses';
  case 'bullet':
    return 'squares';
  default:
    return '';
  }
};

const List = ({ children }) => {
  const type = children[0].props.value.listItem;
  const ordered = type === 'number';
  return (
    <ListComponent variation={getVariation(type)} is={ordered ? 'ol' : 'ul'}>
      {children}
    </ListComponent>
  );
};

List.propTypes = {
  children: PropTypes.node
};

export default List;
