/* eslint-disable */
import { createClient } from '@sanity/client';
import { Environment } from '../utils';
import { CookieBannerSettings } from '../types/cookie-banner-settings';

interface CustomClient {
  fetch: (params: Record<string, any>) => Promise<any>
}

class SanityService extends EventTarget {
  private fetchedContent?: CookieBannerSettings
  private isFetching = false;

  createClientWithPerspective(perspective?: any): ReturnType<typeof createClient> {
    return createClient({
      projectId: '4ms3y72p',
      dataset: Environment.getEnvironmentName(),
      apiVersion: '2021-06-07',
      useCdn: perspective !== 'previewDrafts' || true,
      perspective,
      withCredentials: !!Environment.getSanityToken(),
      token: Environment.getSanityToken(),
      ignoreBrowserTokenWarning: true
    });
  }

  createCustomClient(): CustomClient {
    return {
      fetch: async (params: Record<string, any>) => {
        const queryString = new URLSearchParams(Object.entries(params).map(
          ([key, value]) => [`params[${key}]`, value]
        )).toString();

        const response = await fetch(`${Environment.getCacheUrl()}?${queryString}`);

        const data = await response.json();

        return data.result;
      },
    }
  }

  getSettings(cb: (arg0: CookieBannerSettings) => void) {
    if(this.fetchedContent) {
      cb(this.fetchedContent);
    }

    this.addEventListener('fetched', () => {
      if(!this.fetchedContent) return;

      cb(this.fetchedContent)
    });

    if (!this.isFetching) this.fetchSettings();
  }

  private fetchSettings() {
    this.isFetching = true;

    const query = '*[(_type == $type && domainId == $domainId) || _id == $cookieBannerSettingsId]';
    const domainId = Environment.getCurrentDomainId() || 'DEV';

    const params = {
      domainId,
      type: "cookieBannerSettings",
      cookieBannerSettingsId: `cookieBannerSettings${domainId}`
    };

    if (Environment.getCacheUrl()) {
      this.createCustomClient()
        .fetch(params)
        .then(response => {
          this.fetchedContent = response[0];
        })
        .catch(e =>
          console.error('Error while fetching Cookie Banner data from cache:', e)
        )
        .finally(() => {
          this.dispatchEvent(new Event('fetched'));
          this.isFetching = false;
        })
    } else {
      this.createClientWithPerspective(!!Environment.getSanityToken() ? "previewDrafts" : "published")
        .fetch(query, params)
        .then(response => {
          this.fetchedContent = response[0];
        })
        .catch(e =>
          console.error('Error while fetching Cookie Banner data from Sanity:', e)
        )
        .finally(() => {
          this.dispatchEvent(new Event('fetched'));
          this.isFetching = false;
        })
    }
  }
}

const singleton = new SanityService();

export default singleton;
