import React, {useState, useEffect} from "react";
import SanityService from "../services/sanity";
import { CookieBannerSettings } from "../types/cookie-banner-settings";

export const SanityDataContext = React.createContext<CookieBannerSettings | null>(null);

const SanityDataProvider = ({ children }: { children: React.ReactNode}) => {
  const [data, setData] = useState<CookieBannerSettings | null>(null);

  useEffect(() => {
    SanityService.getSettings((content) => {
      if(content) setData(content);
    });
  }, []);

  return (
    <SanityDataContext.Provider value={data}>
      {children}
    </SanityDataContext.Provider>
  );
};

export { SanityDataProvider };
