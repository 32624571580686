function convertToSlug(text = '') {
  if (typeof text !== 'string') return '';

  return text
    .toLowerCase()
    .trim()
    .replace(/[^\s\w-]+/g, '')
    .replace(/ +/g, '-');
}

export default convertToSlug;
