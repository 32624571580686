import PropTypes from 'prop-types';
import zip from 'lodash/zip';
import { AdaptiveTable } from '@youngcapital/origin-component-library/external';

const Table = props => {
  const { table, label, placeholder } = props;

  const tableRows = table.rows || [];
  const rowData = tableRows.map(tableRow => tableRow.cells);
  const columnTitles = rowData[0].slice(1);
  const rowDataByTitle = rowData.slice(1).reduce((result, row) => {
    const rowTitle = row[0];
    const rowValues = zip(columnTitles, row.slice(1));

    return { ...result, [rowTitle]: rowValues };
  }, {});
  const rowTitles = rowData.slice(1).map(row => row[0]);
  const options = rowTitles.map(value => ({ value, label: value }));

  return (
    <AdaptiveTable
      rowData={rowData}
      rowDataByTitle={rowDataByTitle}
      options={options}
      placeholderText={placeholder}
      fieldProps={{ name: 'table-select', label }}
    />
  );
};

Table.propTypes = {
  table: PropTypes.object,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired
};

export default Table;
