import React, {useContext} from 'react';
import {SanityDataContext} from "../../context/sanity-data-context";

export default function PrivacyLinks() {
  const context = useContext(SanityDataContext);
  return (
    <div className="flex-row center-xs start-sm mt-6x mt-sm-3x gap-4x gap-sm-2x">
      <a
        className="cookie-modal__u-font--body-small"
        href={context?.dataPrivacyUrl}
        target="_blank"
        rel="noopener noreferrer"
        data-test-id="privacy-link"
      >
        {context?.dataPrivacyTitle}
      </a>
      <a
        className="cookie-modal__u-font--body-small"
        href={context?.imprintUrl}
        target="_blank"
        rel="noopener noreferrer"
        data-test-id="imprint-link"
      >
        {context?.imprintTitle}
      </a>
    </div>
  );
}
