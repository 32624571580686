import { Fragment, useState, ChangeEventHandler } from "react";
import Switch from "../Switch";
import { Company } from "./company";
import { CookieBannerSettings } from "../../types/cookie-banner-settings";

// isDisabled not used?
type InformationSectionProps = { title: string, info: string, description: string, companies: Company[], isChecked: boolean, isDisabled: boolean, onToggle: ChangeEventHandler<HTMLInputElement>, cookieName: string, context: CookieBannerSettings | null };

const InformationSection: React.FunctionComponent<InformationSectionProps> = ({
  title,
  info,
  description,
  companies,
  isChecked,
  onToggle,
  cookieName,
  context
}) => {
  const [isVisible, setisVisible] = useState(false);

  const toggleExtraInfoSection = () => {
    setisVisible(!isVisible);
  };

  return (
    <div className="pl-6x pl-sm-3x pr-6x pr-sm-3x pt-6x pt-sm-3x cookie-modal__background--greyscale-white mb-1x ml-4x-negative ml-sm-0 mr-4x-negative mr-sm-0">
      <div className="cookie-modal-input between-xs mb-1x">
        <label
          className="cookie-modal-input__switch-label"
          htmlFor={`CookieInputCheckbox_${cookieName}`}
        >
          <span className="cookie-modal__u-font--body-xlarge">
            {title}
          </span>
        </label>
        <Switch
          onToggle={onToggle}
          name={cookieName}
          isChecked={isChecked}
        />
      </div>
      <p className="cookie-modal__u-font--body-large">{info}</p>
      <div className="flex-row center-xs">
        <div
          className="pb-2x pl-2x pr-2x pt-2x flex-row cookie-modal__read-more"
          onClick={toggleExtraInfoSection}
        >
          <span className="cookie-modal__u-font--body-medium mr-1x">
            {context?.readMoreBtnText}
          </span>
          {!isVisible ? (
            <i className="nyc-icon nyc-icon-arrow-down"></i>
          ) : (
            <i className="nyc-icon nyc-icon-arrow-up"></i>
          )}
        </div>
      </div>
      {isVisible ? (
        <div className="cookie-modal__background--greyscale-10 pl-6x pl-sm-3x pr-6x pr-sm-3x pt-6x pt-sm-3x pb-6x pb-sm-3x mb-4x ml-6x-negative ml-sm-3x-negative mr-6x-negative mr-sm-3x-negative">
          {description}
          <hr className="mt-2x mb-2x cookie-modal__border--greyscale-2" />
          <div className="cookie-modal__u-font--body-large flex-column">
            <span className="mb-1x-negative cookie-modal__u-font--body-xlarge">
              {context?.cookieDefinitionsHeading}
            </span>

            <>
              {companies?.map((company) => (
                <Fragment key={company.postalCode}>
                  <span className="mt-sm-2x mt-1x cookie-modal__u-font--body-xlarge">
                    {context?.cookieDefinitionsCompany}
                  </span>
                  <span className="cookie-modal__u-font--body-medium">
                    {company.companyName}
                  </span>
                  <span className="cookie-modal__u-font--body-medium">
                    {company.streetAddres}
                  </span>
                  <span className="cookie-modal__u-font--body-medium">
                    {company?.apt}
                  </span>
                  <span className="cookie-modal__u-font--body-medium">
                    {company.postalCode}
                    {company.region}
                  </span>
                  <span className="mb-1x cookie-modal__u-font--body-medium">
                    {company.country}
                  </span>
                  <span className="cookie-modal__u-font--body-xlarge">
                    {context?.cookieDefinitionsPrivacyPolicy}:
                  </span>
                  <a
                    className="mb-2x cookie-modal__u-font--body-medium"
                    href={company.privacyUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {company.privacyUrl}
                  </a>
                  <>{company.cookies?.map((cookie) => (
                    <Fragment key={cookie.cookieName}>
                      <ul>
                        <li>
                          <span className="cookie-modal__u-font--body-medium">
                            <b>{context?.cookieDefinitionsCookieName}:</b> {cookie.cookieName}
                          </span>
                          <br />
                          <span className="cookie-modal__u-font--body-medium">
                            <b>{context?.cookieDefinitionsCookiePurpose}:</b> {cookie.cookiePurpose}
                          </span>
                          <br />
                          <span className="cookie-modal__u-font--body-medium">
                            <b>{context?.cookieDefinitionsCookieExpiry}:</b> {cookie.cookieExpire}
                          </span>
                          <br />
                          <span className="mb-1x cookie-modal__u-font--body-medium">
                            <b>{context?.cookieDefinitionsCookieType}:</b> {cookie.cookieType}
                          </span>
                        </li>
                      </ul>
                    </Fragment>
                  ))}</>
                </Fragment>
              ))}
            </>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default InformationSection;
