import { useContext } from "react";

import {ConsentSettings} from "../services";
import { SanityDataContext } from "../context/sanity-data-context";
import GtmScript from "../components/GtmScript";
import BlueConicScript from "../components/BlueConicScript";
import { Environment } from './../utils';

export default function ScriptsContainer({ settings }: { settings: ConsentSettings }) {
  const context = useContext(SanityDataContext);

  if (!context) return null;

  /**
   * For some domains we allow loading the scripts and then reporting the
   * consentLevel, for those it is okay to load the scripts regardless of
   * the given consentLevel.
   */
  if (settings.isOnlyEssential && !context.allowInitialScriptsLoading) return null;

  return(
    <>
      <GtmScript />
      {Environment.isBlueConicEnabled() && <BlueConicScript/>}
    </>
  );
}
