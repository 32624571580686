import React, { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import './development-view.css';

import { LocalStorageService } from "./services";

function DevView() {
  const [updated, setUpdated] = React.useState(new Date());

  React.useEffect(() => {
    const interval = setInterval(() => {
      setUpdated(new Date());
    }, 1000);

    () => clearInterval(interval);
  }, []);

  return (
    <div className="cookie-banner-debug">
      <div className="cookie-banner-debug--buttons">
        <button
          onClick={() => {
            LocalStorageService.clearSettingsInLocalStorage();
            window.location.reload();
          }}
        >
            Clear LocalStorage & Reload
        </button>
        <button onClick={() => window.showSettingsModal()}>
            Settings Modal
        </button>
        <a href="#cookie-settings">Open using an anchor (#cookie-settings)</a>
      </div>
      <div className="cookie-banner-debug--code">
        <h2>Local Storage:</h2>
        <pre>{JSON.stringify(LocalStorageService.loadSettingsFromLocalStorage(), undefined, 2)}</pre>
        <button onClick={LocalStorageService.clearSettingsInLocalStorage}>
            Clear
        </button>
      </div>
      <div className="cookie-banner-debug--code">
        <h2>Datalayer:</h2>
        <pre>{JSON.stringify(window.dataLayer, undefined, 2)}</pre>
      </div>
      <div className="cookie-banner-debug--code">
        <h2>Environment:</h2>
        <pre>NODE_ENV: {JSON.stringify(process.env.NODE_ENV)}</pre>
      </div>
      <div className="cookie-banner-debug--ticker">
        <pre>{JSON.stringify(updated)}</pre>
      </div>

    </div>


  )
}

export default function renderDevelopmentView() {

  const debugEl = document.getElementById("cookie-banner-debug");
  if(!debugEl) return;

  const root = ReactDOM.createRoot(debugEl);


  root.render(
    <StrictMode>
      <DevView />
    </StrictMode>
  );
}
