import convertToSlug from './convert-to-slug';

const HEADING_ID_TYPES = ['tocHeading', 'scrollLink'];

const createHeadingId = (markDefs = []) =>
  (markDefs.length ? markDefs : [markDefs])
    .reduce(
      (acc, curr) =>
        HEADING_ID_TYPES.includes(curr._type) && curr.enabled
          ? [...acc, convertToSlug(curr.id || curr._key)]
          : acc,
      []
    )
    .join(' ');

export default createHeadingId;
