const BFF_PRODUCTION_URL = "https://cms-pages-bff.production-1.nxdev.nl/track-user-event";
import ConsentSettings from "./consent-settings-service";

export function submitPreferences(consentSettings: ConsentSettings) {
  const payload = {
    consentSettings,
    hostname: window.location.host,
    deviceType: window.innerWidth < 768 ? "mobile" : "desktop",
  };

  return fetch(BFF_PRODUCTION_URL, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(payload),
  }).catch((error) => {
    console.error(error);
  });
}
