import ConsentSettings from "./consent-settings-service";

/**
 * Either loading the script or setting the settings might come first,
 * that race-condition needs to be covered, therefore the
 * we need to store the settings and wait.
 */
export class BlueConicService {
  private scriptHasLoaded = false;
  private settingsAreReported = false;

  private settings: ConsentSettings | undefined = undefined;

  handleScriptLoading() {
    this.scriptHasLoaded = true;
    this.reportSettings();
  }

  setConsent(settings: ConsentSettings) {
    this.settings = settings;
    this.reportSettings()
  }

  private reportSettings() {
    if (!this.scriptHasLoaded || this.settingsAreReported) return;
    if (typeof window.blueConicClient === 'undefined') return;
    if (typeof this.settings === 'undefined') return;

    const settings = this.settings;

    const profile = window.blueConicClient.profile.getProfile();

    profile.setConsentedObjectives(settings.acceptedSettings().map(c => 'consent' + c));
    profile.setRefusedObjectives(settings.rejectedSettings().map(c => 'consent' + c));

    window.blueConicClient.profile.updateProfile(this, () => {
      console.info('blueConicClient profile was updated');
    });

    this.settingsAreReported = true;
  }
}

const singleton = new BlueConicService();

export default singleton;
