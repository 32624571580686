import React, { useState, useContext, useCallback } from "react";

import EntryScreenModal from "../modals/entry-screen-modal";
import SettingsModal from "../modals/settings-modal";
import { SanityDataContext } from "../context/sanity-data-context";
import { Environment } from "../utils";
import useLocationHashCallback from "../hooks/use-location-hash";
import {ConsentSettings} from "../services";
import {onConsentEventHandler} from "../types/on-consent-event-handler";

type ModalContainerProps = {
  onSettingsChoice: onConsentEventHandler,
  settings: ConsentSettings,
  savedSettings: ConsentSettings | null
};

function ModalWithContext({ onSettingsChoice, settings, savedSettings }: ModalContainerProps) {
  const context = useContext(SanityDataContext);

  const isPrivacyOrImprint = context && Environment.isPrivacyOrImprint(context.dataPrivacyUrl, context.imprintUrl);
  const [modal, setModal] = useState(!isPrivacyOrImprint && !savedSettings && 'cookie');

  const handleSettingsChoice = (consentSettings: ConsentSettings, text: string) => {
    onSettingsChoice(consentSettings, text);
    setModal(false);
  };

  const openSettingsModal = useCallback(() => {
    setModal("settings");
  }, [setModal]);

  useLocationHashCallback('#cookie-settings', openSettingsModal);
  window.showSettingsModal = openSettingsModal;

  return (
    <>
      {modal === "cookie" && (
        <EntryScreenModal
          onSubmit={handleSettingsChoice}
          onSettings={openSettingsModal}
        />
      )}

      {modal === "settings" && (
        <SettingsModal
          onSubmit={handleSettingsChoice}
          value={settings}
        />
      )}
    </>
  );
}

export default function ModalContainer(props: ModalContainerProps) {
  const context = useContext(SanityDataContext);
  if (!context) return null;

  return <ModalWithContext {...props} />
}
