import { StrictMode } from "react";
import ReactDOM from "react-dom/client";

import { AppContainer } from "./containers/app-container";
import { HelmetProvider } from "react-helmet-async";

import { SanityDataProvider } from "./context/sanity-data-context";
import renderDevelopmentView from "./development-view";

export function renderCookieBanner() {
  const modalEl = document.createElement("div");
  modalEl.dataset.testId = 'cookie-banner';

  document.body.appendChild(modalEl);
  const root = ReactDOM.createRoot(modalEl);

  root.render(
    <StrictMode>
      <HelmetProvider>
        {
          process.env.NODE_ENV === "development" && (
            <>
              <link
                rel="stylesheet"
                type="text/css"
                href="https://cdn.youngcapital.nl/style-guide/external/10.10.9/style.css"
              />
              <link
                rel="stylesheet"
                type="text/css"
                href="https://cdn.youngcapital.nl/style-guide/icons/latest/style.css"
              />
              <link
                rel="stylesheet"
                type="text/css"
                href="https://fonts.googleapis.com/css?family=Work+Sans:400,700,900"
              />
            </>
          )
        }
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdn.youngcapital.nl/style-guide/external/10.10.9/external/cookie-modal.css"
        />

        <SanityDataProvider>
          <AppContainer />
        </SanityDataProvider>
      </HelmetProvider>
    </StrictMode>
  );
}

window.addEventListener('load', renderCookieBanner);

if (process.env.NODE_ENV === "development")
  window.addEventListener('load', renderDevelopmentView);

declare global {
  interface Window {
    cookieBanner: {
      loadOnPreview: () => void;
    }
  }
}

window.cookieBanner = {
  loadOnPreview: () => {
    renderCookieBanner();
    renderDevelopmentView();
  }
}
