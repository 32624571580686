const isValidCard = card => Boolean(card && card.content);

const isValidCta = cta => Boolean(cta && cta.label);

const isValidHeading = heading =>
  Boolean(heading && (heading.children || heading.text) && heading.htmlTag);

const isValidImage = image => Boolean(image && image.asset);

const isValidTextBlock = textBlock => Boolean(textBlock && textBlock.content);

const isValidForm = form =>
  Boolean(
    form &&
      (form.id || form._id) &&
      form.domainId &&
      form.controls &&
      form.submitButton &&
      form.postSubmissionContent
  );

const isValidFormInput = input => Boolean(input && input.name && input.label);
const isValidFormTextArea = textArea =>
  Boolean(textArea && textArea.name && textArea.label);
const isValidFormCheckRadioGroup = checkRadio =>
  Boolean(
    checkRadio &&
      checkRadio.name &&
      Array.isArray(checkRadio.entries) &&
      checkRadio.entries.length ===
        checkRadio.entries.filter(entry => entry.label).length &&
      checkRadio.type &&
      checkRadio.label
  );
const isValidSelect = select =>
  Boolean(
    select && select.name && select.label && Array.isArray(select.entries)
  );
const isValidSubmitButton = button =>
  Boolean(button && button.labelNormal && button.labelSubmitting);

const isValidSocialLink = link => Boolean(link.title && link.icon && link.url);

const isValidLink = link =>
  Boolean(
    link &&
      link.children &&
      link.value &&
      (link.value.url || link.value.href || link.value.email)
  );

const isValidFileInput = fileInput =>
  Boolean(fileInput && fileInput.label && fileInput.name);

const isValidArticle = article =>
  Boolean(
    article &&
      article.seo &&
      article.seo.slug &&
      article.category &&
      article.title &&
      article.sections
  );

const isValidCategory = category =>
  Boolean(
    category &&
      category.image &&
      category.title &&
      category.image.asset.url &&
      category.seo &&
      category.seo.slug &&
      category.seo.slug.current
  );

const isValidRichContent = richContent =>
  Boolean(richContent.content && Array.isArray(richContent.content));

const isValidSeo = page =>
  Boolean(page && page.seo && page.seo.slug && page.seo.slug.current);

const isValidVideosObject = videos =>
  Boolean(
    videos && videos.heading && videos.entries && videos.entries.length > 0
  );

const isValidScrollLink = scrollLink =>
  Boolean(scrollLink && scrollLink.value && scrollLink.value.enabled);

export {
  isValidCard,
  isValidCta,
  isValidHeading,
  isValidImage,
  isValidTextBlock,
  isValidForm,
  isValidFormInput,
  isValidFormTextArea,
  isValidFormCheckRadioGroup,
  isValidSelect,
  isValidSubmitButton,
  isValidSocialLink,
  isValidLink,
  isValidFileInput,
  isValidArticle,
  isValidCategory,
  isValidRichContent,
  isValidSeo,
  isValidVideosObject,
  isValidScrollLink
};
