import PropTypes from 'prop-types';
import { isValidLink } from './helpers/validators';
import getAttributesFromBehaviour from './helpers/get-attributes-from-behaviour';

const AnchorElement = props => {
  if (!isValidLink(props)) return null;
  const {
    className,
    children,
    value: { url, href, email, behaviour, rel }
  } = props;

  const anchorLink = (email && `mailto:${email}`) || url || href;
  return (
    <a
      href={anchorLink}
      className={className}
      rel={rel || ''}
      {...getAttributesFromBehaviour(behaviour)}
    >
      {children}
    </a>
  );
};

AnchorElement.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  value: PropTypes.shape({
    url: PropTypes.string,
    href: PropTypes.string,
    email: PropTypes.string,
    behaviour: PropTypes.oneOf(['default', 'download', 'newWindow']),
    rel: PropTypes.oneOf(['nofollow', 'sponsored', ''])
  })
};

AnchorElement.defaultProps = {
  value: {
    behaviour: 'default'
  }
};

export default AnchorElement;
