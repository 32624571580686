import domains, {type Domain} from "@team-cms/cms-utilities/domains";

const currentScript = document.currentScript; // Prevent garbage-collection

const supportedEnvironmentNames = [
  'development',
  'staging',
  'acceptance',
  'production'
];

export type EnvironmentName = typeof supportedEnvironmentNames[number];

class Environment {
  private scriptSettings = this.getScriptSettings();
  private domain: Domain | undefined;

  constructor() {
    if(this.scriptSettings.domainId) {
      this.domain = this.getDomainById(this.scriptSettings.domainId);
    } else {
      this.domain = this.getDomainByCurrentHost();
    }
  }

  isBlueConicEnabled(): boolean {
    return this.scriptSettings.isBlueConicEnabled;
  }

  isBFFEnabled() {
    const isProduction = this.scriptSettings.isProduction;
    const isTestingMode = this.scriptSettings.isTestingMode;

    return  isProduction || isTestingMode;
  }

  getCurrentDomainId(): string | undefined {
    return this.domain?.id;
  }

  getEnvironmentName(): EnvironmentName {
    const envNameSetting = this.scriptSettings.environmentName;
    if(envNameSetting && supportedEnvironmentNames.includes(envNameSetting)) return envNameSetting;

    const hostnames = this.domain?.hostname || {};
    const runningEnv = Object.keys(hostnames).find(env => window.location.host === hostnames[env]) as EnvironmentName | undefined;

    return runningEnv ?? 'development';
  }

  getGtmId() {
    return this.domain?.gtmId;
  }

  getCacheUrl() {
    return this.scriptSettings.useCacheFromUrl;
  }

  isPrivacyOrImprint(dataPrivacyUrl?: string, imprintUrl?: string): boolean {
    return [dataPrivacyUrl, imprintUrl].includes(window.location.pathname);
  }

  getSanityToken() {
    return this.scriptSettings.useCacheFromUrl ? undefined : process.env.REACT_APP_SANITY_READ_TOKEN || this.scriptSettings.sanityToken;
  }

  private getScriptSettings() {
    const get = attr => currentScript?.getAttribute(attr)

    let scriptSettings = {
      domainId: get("data-domain-id") as string | undefined,
      isProduction: (get("data-is-production")?.includes("true") || false) as boolean,
      environmentName: get("data-environment-name") as EnvironmentName | undefined,
      isTestingMode:  window.location.search.includes('isTestingMode=true') as boolean,
      isBlueConicEnabled: (get("data-is-blue-conic-enabled")?.includes("true") || false) as boolean,
      sanityToken: get("data-sanity-token") as string | undefined,
      useCacheFromUrl: get("data-use-cache-from-url") as string | undefined
    }

    if (scriptSettings.useCacheFromUrl) {
      scriptSettings = {
        ...scriptSettings,
        sanityToken: undefined,
      }
    }

    return scriptSettings;
  }

  private getDomainById(id): Domain | undefined {
    return domains.find(domain => domain.id === id)

  }

  private getDomainByCurrentHost(): Domain | undefined {
    const currentHostname = window.location.host;

    return domains.find(domain => {
      for (const env in domain.hostname) {
        if (domain.hostname[env] === currentHostname) return true;
      }
      return false;
    });
  }
}

const singleton = new Environment();

export default singleton;
